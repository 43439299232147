<template>
  <div class="container mb-5">
    <div class="row" style="justify-content: center">
      <div class="col-md-5 mb-3">
        <label for="exampleFormControlInput1" class="form-label">Nome</label>
        <b-form-input
          v-model="user.first_name"
          placeholder="Nome"
          class="input"
        ></b-form-input>
      </div>
      <div class="col-md-5 mb-3">
        <label for="exampleFormControlInput1" class="form-label"
          >Sobrenome</label
        >
        <b-form-input
          v-model="user.last_name"
          placeholder="Sobrenome"
          class="input"
        ></b-form-input>
      </div>
    </div>
    <div class="row mt-2" style="justify-content: center">
      <div class="col-md-5 mb-3">
        <label for="exampleFormControlInput1" class="form-label">E-mail</label>
        <b-form-input
          v-model="user.email"
          type="email"
          placeholder="Email"
          class="input"
        ></b-form-input>
      </div>
      <div class="col-md-5">
        <label for="inputState">Tipo de usuário</label>
        <select
          id="inputState"
          v-model="user.account_type"
          class="form-control"
        >
          <option>Selecione o tipo de usuário</option>
          <option :value="0">Diretor</option>
          <option :value="1">Gestor</option>
          <option :value="2">Colaborador</option>
        </select>
      </div>
    </div>

    <div class="row mt-2" style="justify-content: center">
      <div class="col-md-5 mb-3">
        <label for="exampleFormControlInput1" class="form-label">Senha</label>
        <b-form-input
          v-model="user.password"
          :type="passwordFieldType"
          autocomplete="new-password"
          placeholder="Senha"
          class="input"
        ></b-form-input>

        <!-- botão de visualizar senha -->

        <i
          @click="switchVisibility"
          v-on:click="switchEye()"
          class="material-icons-outlined field-icon toggle-password"
          id="eyechange"
          >visibility_off
        </i>
      </div>
      <div class="col-md-5 mb-3">
        <label for="exampleFormControlInput1" class="form-label"
          >Repita a senha</label
        >
        <b-form-input
          v-model="user.re_password"
          :type="passwordFieldType"
          autocomplete="new-password"
          placeholder="Senha"
          class="input"
        ></b-form-input>

        <!-- botão de visualizar senha -->

        <i
          @click="switchVisibility"
          v-on:click="switchEye()"
          class="material-icons-outlined field-icon toggle-password"
          id="eyechange"
          >visibility_off
        </i>
      </div>
      <!--<div class="col-md-3">
        <label for="exampleFormControlInput1" class="form-label">Área</label>
        <b-form-input
          list="my-list-area"
          v-model="user.area"
          placeholder="Selecione a área"
        ></b-form-input>

        <datalist id="my-list-area">
        <option selected>Escolha...</option>
          <option id="area" v-for="(area, i) in areas" :key="i">
            {{ area.name }}
          </option>
        </datalist>
      </div>-->

      <div class="col-md-5">
        <label for="inputState">Setor</label>
        <select id="inputState" v-model="user.department" class="form-control">
          <option :value="setor.id" v-for="(setor, i) in departments" :key="i">
            {{ setor.name }}
          </option>
        </select>
      </div>

      <div class="col-md-5"></div>
    </div>
    <!--
    <div class="row mt-4" style="justify-content: center">
      <div class="col-md-5">
        <label>Aplicativos do usuário:</label>
        <div class="border p-3">Aqui vão ficar os apps</div>
      </div>
      <div class="col-md-5">
        <label>Aplicativos disponíveis:</label>
        <div class="border p-3">Aqui vão ficar os apps</div>
      </div>
    </div>
    -->

    <div class="row mt-5" style="justify-content: flex-end">
      <b-button class="mr-2" @click="cancelar">Cancelar</b-button>
      <b-button
        variant="danger"
        class="px-4"
        v-if="$route.params.id !== undefined"
        @click="editUser"
        >Salvar</b-button
      >
      <b-button variant="danger" class="px-4" v-else @click="createUser"
        >Criar</b-button
      >
      <div class="col-1"></div>
    </div>
  </div>
</template>

<style scoped lang="sass">
.field-icon
  color: #A9A9A9
  float: right
  margin-left: -25px
  margin-top: -29.5px
  margin-right: 10px
  position: relative
  z-index: 2
  cursor: pointer
</style>

<script>
import AreaService from "../../../services/AreaService/AreaService";
import DepartmentService from "../../../services/DepartmentService/DepartmentService";

import UserService from "../../../services/UserService/UserService";
import Swal from "sweetalert2";

export default {
  name: "ProfileHead",
  props: ["user", "users"],
  data() {
    return {
      areas: [],
      areaService: null,
      departments: [],
      departmentService: null,

      passwordFieldType: "password",
    };
  },

  mounted() {
    this.userService = new UserService();
    this.areaService = new AreaService();
    this.getAllAreas();
    this.departmentService = new DepartmentService();
    this.getAllDepartments();
  },

  methods: {
    async getAllAreas() {
      this.areas = await this.areaService.allAreas();
    },

    async getAllDepartments() {
      this.departments = await this.departmentService.allDepartments();
    },

    async createUser() {
      console.log(this.user.account_type);
      if (this.user.password !== this.user.re_password) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "As senhas devem ser iguais!",
        });
        return;
      }

      await this.userService.createUser(this.user);
    },

    async editUser() {
      if (this.user.password.length > 0) {
        if (this.user.password === this.user.re_password) {
          await this.userService.createPassword(
            this.user.id,
            this.user.password,
            this.user.re_password
          );
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "As senhas devem ser iguais!",
          });
          return;
        }
      }
      delete this.user.password;
      await this.userService.editUser(this.user);
      this.router.push("/users");
    },

    getType(type) {
      switch (type) {
        case 0:
          return "Diretor";
        case 1:
          return "Gestor";
        case 2:
          return "Colaborador";
      }
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },

    switchEye() {
      var element = document.getElementById("eyechange");
      if (this.passwordFieldType === "password") {
        element.innerHTML = "visibility_off";
      } else {
        element.innerHTML = "visibility";
      }
    },
    cancelar() {
      return this.$router.push("/users");
    },
  },
};
</script>
