<template>
  <div class="mt-5">
    <ProfileHead :user="user" :userService="userService" />
    <ProfileBody :user="user" />
  </div>
</template>

<style scoped lang="sass"></style>

<script>
import ProfileHead from "./components/ProfileHead.vue";
import ProfileBody from "./components/ProfileBody.vue";

import UserService from "../../services/UserService/UserService";

export default {
  components: { ProfileHead, ProfileBody },
  name: "ProfilePage",
  data() {
    return {
      userService: null,
      users: [],
      user: null,
    };
  },
  mounted() {
    this.userService = new UserService();
    this.getAllUsers();
    this.getId();
  },

  methods: {
    async getAllUsers() {
      this.users = await this.userService.allUsers();
    },

    async getId() {
      console.log(this.$route.params.id);
      if (!this.$route.params.id) {
        this.user = {
          departments: [],
          username: "",
          first_name: "",
          last_name: "",
          account_type: 2,
          phone: null,
          send_email: true,
          job_title: null,
          email: "",
        };
      } else {
        let id = this.$route.params.id;
        this.user = await this.userService.getUserId(id);
        this.user.password = "";
      }
    },
  },
};
</script>
