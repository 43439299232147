<template>
  <div class="container mt-4 mb-3">
    <div class="row" style="justify-content: space-around; align-items: center">
      <div class="col-md-6" v-if="user.first_name === ''">
        <h1 style="font-size: 30px; color: #a1000c; font-weight: bold">
          Novo Usuário
        </h1>
        <p>Preencha as informações sobre o novo usuário</p>
      </div>
      <div class="col-md-6" v-else>
        <h1 style="font-size: 30px; color: #a1000c; font-weight: bold">
          {{ user.first_name + " " + user.last_name }}
        </h1>
        <p>Veja informações sobre {{ user.first_name }}</p>
      </div>
      <div>
        <img src="/assets/img/profile.png" />
      </div>
    </div>
    <hr class="mt-4" style="background: #a1000c; width: 88%" />
  </div>
</template>

<style scoped lang="sass"></style>

<script>
export default {
  name: "ProfileHead",
  props: ["user"],
};
</script>
